import React, { ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';

import { useTheme } from '../context/themeContext';

const GlobalStyles = createGlobalStyle<{ darkTheme: boolean }>`
  :root {
    --font-family: 'DM Sans', system-ui, sans-serif;
    --font-size-xs: clamp(0.25rem, calc( 12px + 0.20vw ), 0.7rem);
    --font-size-sm: clamp(0.625rem, calc( 12px + 0.360vw ), 0.825rem);
    --font-size-base: clamp(1rem, calc( 12px + 0.540vw ), 1.125rem);
    --font-size-lg: clamp(1.575rem, calc( 12px + 1.800vw ), 2.025rem);
    --font-size-xl: clamp(1.913rem, calc( 12px + 2.475vw ), 2.587rem);
    --font-weight-bold: 700;

    --color-pink: #F7CAC9;
    --color-dark-blue: #383F51;

    --color-text: ${(props) => (props.darkTheme ? `var(--color-pink)` : `var(--color-dark-blue)`)};
    --color-bg: ${(props) => (props.darkTheme ? `var(--color-dark-blue)` : `var(--color-pink)`)};

  }

  html {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    color: var(--color-text);
    background-color: var(--color-bg);
  }

  body {
    margin: 32px 32px 16px 32px;
    margin: 24px 24px 12px 24px;
  }

  h1 { 
    letter-spacing: 0.2px;
    font-size: var(--font-size-xl);
  }

  h2 {
    letter-spacing: 0.3px;
    font-size: var(--font-size-lg);
  }

  p {
    letter-spacing: 0.1px;
    line-height: 1.4;
  }
`;

export const GlobalStylesComponent = ({ children }: { children: ReactNode }) => {
  const { isDarkTheme } = useTheme();
  return (
    <>
      <GlobalStyles darkTheme={isDarkTheme} />
      {children}
    </>
  );
};
