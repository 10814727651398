import React, { createContext, useState } from 'react';

type ThemeContextType = {
  isDarkTheme: boolean;
  switchThemeIsActive: boolean;
  currentBgColor: 'dark' | 'light' | '';
  switchThemeCallback: (arg: boolean) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  isDarkTheme: false,
  switchThemeCallback: () => {},
  switchThemeIsActive: false,
  currentBgColor: '',
});

function ThemeProvider({ children }: { children: any }) {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [switchThemeIsActive, setSwitchThemeIsActive] = useState(false);
  const [currentBgColor, setCurrentBgColor] = useState<'dark' | 'light' | ''>('');

  const switchThemeCallback = (isDark: boolean) => {
    setCurrentBgColor(isDark ? 'dark' : 'light');
    setSwitchThemeIsActive(true);

    setTimeout(() => {
      setIsDarkTheme(!isDarkTheme);
    }, 900);

    setTimeout(() => {
      setSwitchThemeIsActive(false);
    }, 1200);
  };

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: ThemeContextType = { isDarkTheme, switchThemeIsActive, currentBgColor, switchThemeCallback };
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

function useTheme() {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export { ThemeProvider, useTheme };
