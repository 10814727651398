import React from 'react';

import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';

import { ThemeProvider } from './src/context/themeContext';
import { GlobalStyles } from './src/GlobalStyles';

type WrapElementProps = {
  element: React.ReactNode;
};

// Wraps every page in a component
export const wrapRootElement = ({ element }: WrapElementProps) => <ThemeProvider>{element}</ThemeProvider>;

export const wrapPageElement = ({ element }: WrapElementProps) => <GlobalStyles>{element}</GlobalStyles>;
